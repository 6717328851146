import React, { Fragment, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import styles from './styles.module.scss';
import AriaButton from '../AriaComponents/AriaButton';
import { ButtonSize, ButtonStyle, StyledButton } from './StyledButton';
import Icon from '../Icon';
import { PlaceAboveProps, useOnClickOutside } from '../../helpers';
import usePlaceOver from '../../helpers/placeAbove';
import LoadingSpinner from '../loading/LoadingSpinner';

/**
 * If using a custom component to render list options.
 * Selected and onSelected logic should be handled
 * by the parent component
 */
type Props<T> = OptionProps<T> & CustomOpenProps & SharedProps;

type CustomOpenProps =
    | {
          open: boolean;
          setOpen: (val: boolean) => void;
      }
    | {
          open?: never;
          setOpen?: never;
      };

type OptionProps<T> =
    | {
          options: StyledDropdownOption[];
          onSelect?: (val: string) => void;
          selected?: string | string[];
          component?: never;
          searchProps?: SearchProps;
      }
    | {
          options: T[];
          onSelect?: never;
          selected?: never;
          component: React.FC<T>;
          searchProps?: never;
      }
    | {
          options: {
              label: string;
              value: T;
          }[];
          onSelect: (val: T) => void;
          selected?: never;
          component?: never;
          searchProps?: SearchProps;
      };

interface SharedProps {
    buttonOverride?: ReactNode;
    buttonStyle?: ButtonStyle;
    buttonTitle?: string;
    children?: ReactNode;
    className?: string;
    direction?: 'right';
    disabled?: boolean;
    display?: ReactNode;
    iconOnly?: boolean;
    iconSize?: ButtonSize;
    loading?: boolean;
    noDownArrow?: boolean;
    placeAboveProps?: Pick<PlaceAboveProps, 'xPos' | 'yPos' | 'offset' | 'flipped'>;
    placeHolder?: string;
}

export type StyledDropdownOption = (
    | {
          divider?: never;
          onSelect?: never;
          value: string;
      }
    | {
          divider?: never;
          onSelect: () => void | undefined;
          value?: never;
      }
    | {
          divider?: boolean;
          onSelect?: never;
          value?: never;
      }
) &
    SharedOptionProps;

export interface SharedOptionProps {
    context?: 'danger';
    disabled?: boolean | string;
    label: string | ReactNode;
    large?: boolean;
    selected?: boolean;
}

type SearchProps =
    | {
          searchVal: string;
          setSearchVal: (val: string) => void;
          allowLocalSearch?: never;
      }
    | {
          searchVal?: never;
          setSearchVal?: never;
          allowLocalSearch?: boolean;
      };

const StyledDropdown = <T,>({
    options,
    onSelect,
    selected,
    placeHolder,
    component,
    iconOnly,
    direction,
    display,
    loading,
    iconSize,
    children,
    className,
    placeAboveProps,
    noDownArrow,
    buttonStyle,
    open,
    setOpen,
    buttonTitle,
    buttonOverride,
    disabled,
    searchProps
}: Props<T>) => {
    const [openLocal, setOpenLocal] = useState<boolean>(false);
    const dropdownOpen = open ?? (openLocal || !!searchProps?.searchVal);
    const setDropdownOpen = setOpen ?? setOpenLocal;

    const [searchLocal, setSearchLocal] = useState<string>('');

    const dropdownSearchVal = searchProps?.allowLocalSearch ? searchLocal : searchProps?.searchVal;
    const setDropdownSearch = searchProps?.allowLocalSearch ? setSearchLocal : searchProps?.setSearchVal;

    const dropDownRef = useRef<HTMLUListElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(dropDownRef, () => setDropdownOpen(false));

    usePlaceOver({
        relativeElement: containerRef,
        fixedElement: dropDownRef,
        xPos: 'right',
        yPos: 'bottom',
        display: dropdownOpen,
        flipped: true,
        ...placeAboveProps
    });

    const generateButtonStyle = useCallback((): ButtonStyle => {
        if (iconOnly) {
            return 'text';
        }

        return buttonStyle || 'tertiary';
    }, [iconOnly]);

    const buttonComp = useMemo((): ReactNode => {
        if (buttonOverride) {
            return null;
        }

        if (iconOnly) {
            return <Icon name='threeDots' />;
        }

        let innerDisplay: ReactNode;

        if (loading) {
            innerDisplay = <LoadingSpinner color='mamba' />;
        } else if (dropdownSearchVal) {
            innerDisplay = (
                <p>
                    <mark>{dropdownSearchVal}</mark>
                </p>
            );
        } else if (display) {
            innerDisplay = display;
        } else {
            innerDisplay = (
                <p>
                    {(typeof selected === 'string' &&
                        selected &&
                        options &&
                        options.find(opt => opt.value === selected)?.label) ||
                        placeHolder ||
                        'Select'}
                </p>
            );
        }

        return (
            <>
                {innerDisplay}
                {!noDownArrow ? (
                    <Icon name='arrowDown' width={20} height={20} className={styles.arrow_button} />
                ) : null}
            </>
        );
    }, [
        buttonOverride,
        iconOnly,
        loading,
        display,
        selected,
        options.length,
        placeHolder,
        dropdownSearchVal
    ]);

    const displayOpts = useMemo((): any[] => {
        if (!searchLocal || !Array.isArray(options)) return options;

        const lowerCaseSearch = searchLocal.toLowerCase();

        const opts: any[] = [];

        options.forEach(o => {
            if (o.label.toLowerCase().includes(lowerCaseSearch)) {
                opts.push(o)
            }
        })

        return opts;
    }, [searchLocal, options.length]);

    return (
        <div
            className={[styles.styled_dropdown, className || ''].join(' ')}
            ref={containerRef}
            onBlur={e => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    setDropdownOpen(false);
                    if (setDropdownSearch) {
                        setDropdownSearch('');
                    }
                }
            }}
            data-loading={loading || null}
            data-is-open={dropdownOpen || null}
        >
            {buttonOverride || (
                <StyledButton
                    buttonStyle={generateButtonStyle()}
                    size={iconSize || 'small'}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    iconOnly={iconOnly}
                    title={buttonTitle}
                    disabled={disabled}
                    onKeydown={e => {
                        if (setDropdownSearch) {
                            if (e.key.length === 1) {
                                setDropdownSearch(dropdownSearchVal + e.key);
                            } else {
                                switch (e.key) {
                                    case 'Backspace':
                                        if (dropdownSearchVal) {
                                            setDropdownSearch(
                                                dropdownSearchVal.substring(0, dropdownSearchVal.length - 1)
                                            );
                                        }
                                        break;
                                    case 'Escape':
                                        if (!dropdownSearchVal) {
                                            setDropdownOpen(false);
                                        }
                                        setDropdownSearch('');
                                        break;
                                }
                            }
                        } else {
                            switch (e.key) {
                                case 'Escape':
                                    setDropdownOpen(false);
                                    break;
                            }
                        }
                    }}
                >
                    {buttonComp}
                </StyledButton>
            )}
            <AnimatePresence>
                {dropdownOpen ? (
                    <motion.ul
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        ref={dropDownRef}
                        data-menu-direction={direction || null}
                        className={styles.styled_list}
                    >
                        {displayOpts.map((opt, idx) => {
                            if (component) {
                                return (
                                    <Fragment key={opt.value || idx}>
                                        {component(opt, () => setDropdownOpen(false))}
                                    </Fragment>
                                );
                            }

                            let isSelected;

                            if (typeof selected === 'string' && selected === opt.value) {
                                isSelected = true;
                            }

                            if (Array.isArray(selected) && selected.includes(opt.value)) {
                                isSelected = true;
                            }

                            if (opt.selected) {
                                isSelected = true;
                            }

                            const title = typeof opt.label === 'string' ? opt.label : '';

                            if (opt.divider) {
                                return <p key={`l-${opt.label}-${idx}`}>{opt.label}</p>;
                            }

                            return (
                                <li
                                    key={opt.value || idx}
                                    title={
                                        opt.disabled && typeof opt.disabled === 'string'
                                            ? opt.disabled
                                            : undefined
                                    }
                                >
                                    <AriaButton
                                        dataTags={{
                                            'data-selected': isSelected || null,
                                            'data-is-large': opt.large || null,
                                            'data-is-danger':
                                                opt.danger || opt.context === 'danger' || null
                                        }}
                                        onClick={e => {
                                            if (opt.onSelect) {
                                                opt.onSelect();
                                            } else if (onSelect) {
                                                onSelect(opt.value);
                                            }
                                            if (!e.shiftKey) {
                                                setDropdownOpen(false);
                                            }
                                        }}
                                        title={`Select ${title}`}
                                        disabled={opt.disabled}
                                    >
                                        {typeof opt.label === 'string' ? (
                                            <div>
                                                <p>{opt.label}</p>
                                            </div>
                                        ) : (
                                            opt.label
                                        )}

                                        {isSelected ? <Icon name='tick' /> : null}
                                    </AriaButton>
                                </li>
                            );
                        })}
                        {children}
                    </motion.ul>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

export default StyledDropdown;
