import React from 'react';
import {
    IPhonebookContactDetail,
    isHuntGroup,
    isPhonebookContact,
    isUser
} from '../../../../types';
import {
    addUserForGroupCall,
    handleFindingATransfer,
    selectCallingShortcodesOnUser,
    selectCallWhenAvailableList,
    selectChatEnabled,
    selectCurrentMessageId,
    selectCurrentUserId,
    selectFavouriteContacts,
    selectUserCountryCode,
    selectUserStatus,
    setActiveChat,
    setViewContact,
    startCall,
    updateActivePage,
    updateFavouriteContact
} from '../../../../redux/slices';
import { getValidNumber } from '../Contact';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import { usePostIntegrationMutation } from '../../../../redux/services/chatApi';
import { usePolyglot } from '../../../../context/Polyglot';
import { useContactContext } from '../../../../context/ContactContext/context';
import { useCallContact } from '../hooks';
import StyledDropdown, { StyledDropdownOption } from '../../../StyledComponents/StyledDropdown';

interface WrapperProps {
    menuWalled?: boolean;
    menuFloored?: boolean;
    conference?: boolean;
}

interface BaseProps {
    options: StyledDropdownOption[];
    menuWalled: boolean;
    menuFloored: boolean;
}

interface ContactOptionsProps extends BaseProps {
    conference: boolean;
}

const UserOptions: React.FC<BaseProps> = ({ options, menuWalled, menuFloored }) => {
    const { contact } = useContactContext();
    const { callContact } = useCallContact();
    const { t } = usePolyglot();

    const chatEnabled = useTypedSelector(selectChatEnabled);

    const { listenShortcode, whisperShortcode, bargeShortcode, pickupShortcode } = useTypedSelector(
        state => selectCallingShortcodesOnUser(state, contact.uuid)
    );

    const activeChat = useTypedSelector(state => state.chat.activeChat);
    const userStatus = useTypedSelector(state =>
        isUser(contact) ? selectUserStatus(state, contact.name) : undefined
    );
    const callWhenAvailableList = useTypedSelector(selectCallWhenAvailableList);

    const dispatch = useTypedDispatch();

    const isInCallWhenAvailableList =
        isUser(contact) && callWhenAvailableList.find(c => c.uuid === contact.uuid);

    const openChat = (
        event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        event?.stopPropagation();
        if (activeChat === contact.uuid) {
            dispatch(updateActivePage('chat'));
        }
        dispatch(setActiveChat(contact.uuid));

        // if (setSearchValue) {
        //     setSearchValue('')
        // }
    };

    if (!isUser(contact)) {
        return null;
    }

    options.push({
        label:
            (userStatus?.status === 'open' && t('actions.call')) ||
            (isInCallWhenAvailableList && t('actions.cancel_arranged_call')) ||
            t('actions.call_when_available'),
        onSelect: callContact
    });

    if (chatEnabled) {
        options.push({
            label: t('actions.message'),
            onSelect: openChat
        });
    }

    if (userStatus && userStatus.status === 'early' && pickupShortcode) {
        options.push({
            label: t('actions.pick_up'),
            onSelect: () => {
                dispatch(
                    startCall(
                        `${pickupShortcode.short_code}${contact.extension}`,
                        `${t('actions.pick_up')} ${contact.nickname}`
                    )
                );
            }
        });
    }

    if (listenShortcode) {
        options.push({
            label: t('actions.listen'),
            onSelect: () => {
                dispatch(
                    startCall(
                        `${listenShortcode.short_code}${contact.extension}`,
                        `${t('phrases.listening_to_%n', { n: contact.nickname })}`
                    )
                );
            }
        });
    }

    if (whisperShortcode) {
        options.push({
            label: t('actions.whisper'),
            onSelect: () => {
                dispatch(
                    startCall(
                        `${whisperShortcode.short_code}${contact.extension}`,
                        `${t('phrases.whispering_to_%n', { n: contact.nickname })}`
                    )
                );
            }
        });
    }

    if (bargeShortcode) {
        options.push({
            label: t('actions.join_call'),
            onSelect: () => {
                dispatch(
                    startCall(
                        `${bargeShortcode.short_code}${contact.extension}`,
                        `${t('phrases.joining_to_%n', { n: contact.nickname })}`
                    )
                );
            }
        });
    }

    return <OptionList options={options} menuWalled={menuWalled} menuFloored={menuFloored} />;
};

const GroupOptions: React.FC<BaseProps> = ({ options, menuWalled, menuFloored }) => {
    const { contact } = useContactContext();
    const { callContact } = useCallContact();
    const { t } = usePolyglot();

    if (!isHuntGroup(contact)) {
        return null;
    }

    options.push({
        label: t('actions.call'),
        onSelect: callContact
    });

    return <OptionList options={options} menuWalled={menuWalled} menuFloored={menuFloored} />;
};

const ContactItemOptions: React.FC<ContactOptionsProps> = ({
    conference,
    options,
    menuWalled,
    menuFloored
}) => {
    const { contact } = useContactContext();
    const { t } = usePolyglot();

    const dispatch = useTypedDispatch();

    const countryCode = useTypedSelector(selectUserCountryCode);
    const selectedMessageId = useTypedSelector(selectCurrentMessageId);
    const userId = useTypedSelector(selectCurrentUserId);
    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const [postIntegration] = usePostIntegrationMutation();

    if (!isPhonebookContact(contact)) {
        return null;
    }

    const onSmsClick = (
        detail: IPhonebookContactDetail,
        validNumber: string,
        displayName: string
    ) => {
        if (!detail?.value || !validNumber) return;

        postIntegration({
            voip_user_uuid: userId,
            destination: validNumber,
            channel: 1,
            name: displayName
        });
    };

    const onPhonebookContactCall = (
        detail: IPhonebookContactDetail,
        validNumber: string,
        displayName: string
    ) => {
        if (!detail?.value || !validNumber) return;

        dispatch(startCall(validNumber, displayName));
    };

    const extraOpts: StyledDropdownOption[] = [];

    for (let i = 0; i < contact.details.length || 0; i += 1) {
        const detail = contact.details[i];

        if (detail.type !== 1) {
            continue;
        }

        const validNumber = getValidNumber(detail?.value, countryCode);
        let displayName: string;

        if (contact.first_name && contact.last_name) {
            displayName = `${contact.first_name} ${contact.last_name} (${detail.label})`;
        } else {
            displayName = `${contact.first_name || contact.last_name || contact.company_name} (${
                detail.label
            })`;
        }

        extraOpts.push({
            label: `${detail.label}: ${detail.value}`,
            divider: true
        });

        if (!validNumber) {
            continue;
        }

        if (conference) {
            extraOpts.push({
                label: 'Add To Group',
                onSelect: () => dispatch(addUserForGroupCall(contact))
            });
        } else if (aTransferIsActive) {
            extraOpts.push({
                label: t('actions.transfer'),
                onSelect: () => onPhonebookContactCall(detail, validNumber, displayName)
            });
        } else {
            extraOpts.push({
                label: selectedMessageId ? t('actions.send_sms') : t('phrases.sms_id_not_set'),
                disabled: !selectedMessageId,
                onSelect: () => onSmsClick(detail, validNumber, displayName)
            });
            extraOpts.push({
                label: t('actions.call'),
                onSelect: () => onPhonebookContactCall(detail, validNumber, displayName)
            });
        }
    }

    return (
        <OptionList
            options={options.concat(extraOpts)}
            menuWalled={menuWalled}
            menuFloored={menuFloored}
        />
    );
};

const OptionList: React.FC<BaseProps> = ({ options, menuWalled, menuFloored }) => {
    const { showOptions, useContactAction } = useContactContext();

    return (
        <div className='recent-row__options'>
            <StyledDropdown
                options={options}
                iconOnly
                className={[
                    'contact__options-container',
                    menuWalled ? 'menu_walled' : '',
                    menuFloored ? 'menu_floored' : ''
                ].join(' ')}
                open={showOptions}
                setOpen={val => {
                    useContactAction({
                        type: 'set_show_options',
                        payload: val
                    });
                }}
            />
        </div>
    );
};

export const ContactOptions: React.FC<WrapperProps> = ({
    conference = false,
    menuWalled = false,
    menuFloored = false
}) => {
    const { contact } = useContactContext();
    const { callContact } = useCallContact();

    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const favouriteContacts = useTypedSelector(selectFavouriteContacts);

    const options: StyledDropdownOption[] = [];

    if (aTransferIsActive && !isPhonebookContact(contact)) {
        return (
            <OptionList
                options={[
                    {
                        label: t('actions.transfer'),
                        onSelect: callContact
                    }
                ]}
                menuWalled={menuWalled}
                menuFloored={menuFloored}
            />
        );
    }

    options.push({
        label:
            (isUser(contact) && t('actions.view_user')) ||
            (isHuntGroup(contact) && t('actions.view_hunt_group')) ||
            t('actions.view_contact'),
        onSelect: () => dispatch(setViewContact(contact))
    });

    options.push({
        label: favouriteContacts?.[contact.uuid]
            ? t('actions.remove_from_favourites')
            : t('actions.add_to_favourites'),
        onSelect: () => {
            dispatch(updateFavouriteContact(contact.uuid, !favouriteContacts?.[contact.uuid]));
        }
    });

    if (isPhonebookContact(contact)) {
        return (
            <ContactItemOptions
                options={conference ? [] : options}
                conference={conference}
                menuWalled={menuWalled}
                menuFloored={menuFloored}
            />
        );
    }

    if (conference) {
        return null;
    }

    if (isUser(contact)) {
        return <UserOptions options={options} menuWalled={menuWalled} menuFloored={menuFloored} />;
    }

    if (isHuntGroup(contact)) {
        return <GroupOptions options={options} menuWalled={menuWalled} menuFloored={menuFloored} />;
    }

    return <OptionList options={options} menuWalled={menuWalled} menuFloored={menuFloored} />;
};
